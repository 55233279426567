import React from "react";

import { IGatsbyImageData } from "gatsby-plugin-image";

import {graphql, withPrefix} from "gatsby";
import { useStaticQuery } from "gatsby";

import AdvisorSearch from "@js/component/AdvisorSearch";
import BlockAdvisors from "@js/component/block/BlockAdvisors";
import BlockAdvisorReviews from "@js/component/block/BlockAdvisorReviews";
import BlockHowDoesItWork from "@js/component/block/BlockHowDoesItWork";
import BlockOurCoreValues from "@js/component/block/BlockOurCoreValues";
import BlockWhoWeAre from "@js/component/block/BlockWhoWeAre";
import FeaturedImage from "@js/component/FeaturedImage";
import Main from "@js/component/Main";

import * as classNames from "@css/component/template/Index.module.scss";
import useHtmlDecode from "@js/hook/useHtmlDecode";
import {Helmet} from "react-helmet";
import Heading from "@js/component/Heading";
import PropositionBanner from "@js/component/icon/PropositionBanner";
import BlockSearchAdvisors from "@js/component/block/BlockSearchAdvisors";
import BlockAboutEnergylabels from "@js/component/block/BlockAboutEnergyLabels";
import BlockAboutCustomAdvise from "@js/component/block/BlockAboutCustomAdvise";
import BlockCTA from "@js/component/block/BlockCTA";
import BlockFAQ from "@js/component/block/BlockFAQ";

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        content: string;
        featuredImage: {
            node: {
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                    publicURL: string;
                };
                altText: string;
                height: number;
                mimeType: string;
                sourceUrl: string;
                width: number;
            }
        }
        seo: {
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            title: string;
            twitterDescription: string;
            twitterTitle: string;
            schema: {
                raw: string;
            }
        };
        title: string;
    };
};

/**
 * @type AllAdvisorsReviewsData
 */
type AllAdvisorsReviewsData = {
    allAdvisorsReviews: {
        edges: {
            node: {
                buildingOwnerInitials: string,
                buildingOwnerName: string;
                companyName: string;
                createdAt: string;
                review: number;
                reviewMessage: string;
                url: string;
                advisorImageFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    }
                };
            };
        }[];
    };
};

/**
 * @type AdvertorialData
 */
type AdvertorialData = {
    wordpress: {
        other: {
            advertorials: {
                advertorial: {
                    advertorialImage: {
                        sourceUrl: string;

                    }
                    advertorialLocation: string;
                    advertorialUrl: string;
                }[]
            }
        }
    };
};

/**
 * @type MetaProps
 */
type MetaProps = {
    pageContext: {
        metaTitle: string;
        metaDescription: string;
        metaKeywords: string;
        metaUrl: string;
        title_h1: string;
    }
}

/**
 * @type Data
 */
type Data = WordpressPageData & AdvertorialData & AllAdvisorsReviewsData & MetaProps;

/**
 * @const Index
 */
const Index = (props: Data) => {
    const {
        pageContext
    } = props;


    const data: Data = useStaticQuery(graphql`
        query {
            wordpressPage(isFrontPage: { eq: true }) {
                content
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 1200, quality: 80)
                            }
                            publicURL
                        }
                        altText
                        height
                        mimeType
                        sourceUrl
                        width
                    }
                }
                seo {
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    opengraphDescription
                    opengraphModifiedTime
                    opengraphPublishedTime
                    opengraphPublisher
                    opengraphSiteName
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    title
                    twitterDescription
                    twitterTitle
                    schema {
                        raw
                    }
                }
                title
            }
            wordpress {
                other {
                    advertorials {
                        advertorial {
                            advertorialUrl
                            advertorialLocation
                            advertorialImage {
                                sourceUrl
                            }
                        }
                    }
                }
            }
            allAdvisorsReviews(limit: 10, sort: {order: DESC, fields: createdAt}) {
                edges {
                    node {
                        advisorImageFile {
                            childImageSharp {
                                gatsbyImageData(layout: FIXED, width: 200, quality: 50)
                            }
                        }
                        buildingOwnerInitials
                        buildingOwnerName
                        companyName
                        createdAt
                        review
                        reviewMessage
                        url
                    }
                }
            }
        }
    `);

    const OpenGraphImage = process.env.SITE_URL + data.wordpressPage.featuredImage?.node.localFile.publicURL;
    const HtmlDecode = useHtmlDecode;

    const advertorialHome = data.wordpress.other.advertorials.advertorial?.find(
        (advertorial) => advertorial.advertorialLocation === 'home'
    );

    const OrganisationStructuredData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Woninglabel",
        "description": "Vergelijk alle energielabel-adviseurs op een plek!",
        "url": "/",
        "email": "info@woninglabel.nl",
        "telephone": "+310850162080",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "Computerweg 22",
            "addressLocality": "Utrecht",
            "addressCountry": "NL",
            "addressRegion": "Utrecht",
            "postalCode": "3542DR"
        },
        "vatID": "NL861909689B01",
        "logo":{
            "@type":"ImageObject",
            "@id":"/#logo",
            "inLanguage":"nl",
            "url":"/static/c7d0d2406278b9bd55dd4351e83097f6/Woninglabel_logo_blauw_wit_RGB_social.png",
            "width":1500,
            "height":1500,
            "caption":"Woninglabel.nl"
        },
        "image":{
            "@type":"ImageObject",
            "@id":"/#image",
            "inLanguage":"nl",
            "url":"/static/c7d0d2406278b9bd55dd4351e83097f6/Woninglabel_logo_blauw_wit_RGB_social.png",
            "width":1500,
            "height":1500,
            "caption":"Woninglabel.nl"
        },
    }

    return (
        <Main className={ classNames.main }>
            <Helmet>
                <title>{pageContext.metaTitle ? HtmlDecode(pageContext.metaTitle) : data.wordpressPage?.seo.title}</title>

                {data.wordpressPage?.seo.metaKeywords &&
                    <meta content={data.wordpressPage?.seo.metaKeywords} name="keywords"/>}
                {data.wordpressPage?.seo.metaDesc &&
                    <meta content={data.wordpressPage?.seo.metaDesc} name="description"/>}

                <meta
                    content={`${data.wordpressPage?.seo.metaRobotsNofollow}, ${data.wordpressPage?.seo.metaRobotsNoindex}`}
                    name="robots"/>

                {data.wordpressPage?.seo.opengraphDescription &&
                    <meta content={data.wordpressPage?.seo.opengraphDescription} property="og:description"/>}
                {data.wordpressPage?.seo.opengraphModifiedTime &&
                    <meta content={data.wordpressPage?.seo.opengraphModifiedTime} property="article:modified_time"/>}
                {data.wordpressPage?.seo.opengraphPublishedTime &&
                    <meta content={data.wordpressPage?.seo.opengraphPublishedTime} property="article:published_time"/>}
                {data.wordpressPage?.seo.opengraphPublisher &&
                    <meta content={data.wordpressPage?.seo.opengraphPublisher} property="article:author"/>}
                {data.wordpressPage?.seo.opengraphSiteName &&
                    <meta content={data.wordpressPage?.seo.opengraphSiteName} property="og:site_name"/>}
                {data.wordpressPage?.seo.opengraphTitle &&
                    <meta content={data.wordpressPage?.seo.opengraphTitle} property="og:title"/>}
                {data.wordpressPage?.seo.opengraphType &&
                    <meta content={data.wordpressPage?.seo.opengraphType} property="og:type"/>}
                {data.wordpressPage?.seo.opengraphUrl &&
                    <meta content={data.wordpressPage?.seo.opengraphUrl} property="og:url"/>}

                <meta property="og:image" content={OpenGraphImage}/>
                <meta property="og:image:type" content={data.wordpressPage?.featuredImage?.node.mimeType}/>
                <meta property="og:image:width" content={data.wordpressPage?.featuredImage?.node.width.toString()}/>
                <meta property="og:image:height" content={data.wordpressPage?.featuredImage?.node.height.toString()}/>
                <meta property="og:image:alt" content={data.wordpressPage?.featuredImage?.node.altText}/>

                {data.wordpressPage?.seo.twitterDescription &&
                    <meta content={data.wordpressPage?.seo.twitterDescription} name="twitter:description"/>}
                {data.wordpressPage?.seo.twitterTitle &&
                    <meta content={data.wordpressPage?.seo.twitterTitle} name="twitter:title"/>}

                <script type="application/ld+json" className="yoast-schema-graph">
                    {data.wordpressPage?.seo.schema.raw}
                </script>

                <meta content="pgyo8j6z5gikdiotavfkfafhb2vrri" name="facebook-domain-verification"/>
                <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com"/>

                <script type="application/ld+json">
                    {JSON.stringify(OrganisationStructuredData)}
                </script>
            </Helmet>
            <BlockSearchAdvisors
                title={data.wordpressPage.title}
                content={data.wordpressPage.content }
                image={ data.wordpressPage.featuredImage?.node.localFile.childImageSharp.gatsbyImageData }
                advertorial={ advertorialHome }
            />
            <BlockHowDoesItWork />
            <BlockAdvisorReviews reviews={ data.allAdvisorsReviews.edges } />
            <BlockOurCoreValues />
            <BlockAboutEnergylabels />
            <BlockAboutCustomAdvise />
            <BlockWhoWeAre />
            <BlockFAQ />
            <BlockAdvisors />
            <BlockCTA />
        </Main>
    );
};

export default Index;
